<template>
  <div>
    <loading-panel v-if="loading" />

    <user-edit-component
      v-else
      ref="childData"
      :user="userData"
      :update-form="callUpdateUser" />
  </div>
</template>
<script>
  import UserEditComponent from '@/components/User/UserEditComponent.vue';
  import { mapGetters } from 'vuex';
  import LoadingPanel from '@/components/LoadingPanel.vue';
  import { initialState, updateUser } from '@/modules/Dashboard/Users/userEdit';

  export default {
    components: {
      UserEditComponent,
      LoadingPanel,
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters(['user']),
    },
    created() {
      this.userData.id = this.user.id;
      this.userData.firstName = this.user.firstname;
      this.userData.lastName = this.user.lastname;
      this.userData.email = this.user.email;
      this.userData.stores = this.user.stores.collection;

      if (this.user.roles.includes('ROLE_SUPERADMIN')) {
        this.userData.superAdminRole = true;
      }

      this.loading = false;
    },
    methods: {
      callUpdateUser() {
        updateUser(this);
      },
    },
  };
</script>
<style></style>

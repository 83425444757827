<template>
  <div>
    <loading-panel v-if="loading" />

    <user-edit-component
      v-else
      ref="childData"
      :user="userData"
      :update-form="callUpdateUser" />
  </div>
</template>
<script>
  import UserEditComponent from '@/components/User/UserEditComponent.vue';
  import LoadingPanel from '@/components/LoadingPanel.vue';
  import GetSelectedUser from '@/graphql/users/queries/GetSelectedUser.gql';
  import { initialState, updateUser } from '../userEdit';

  export default {
    components: {
      UserEditComponent,
      LoadingPanel,
    },
    data() {
      return initialState();
    },
    created() {
      this.getSelectedUser(`api/users/${this.$route.params.userId}`);
    },
    methods: {
      callUpdateUser() {
        updateUser(this);
      },
      async getSelectedUser(id) {
        try {
          await this.$apollo.addSmartQuery('getSelectedUser', {
            query: GetSelectedUser,
            fetchPolicy: 'no-cache',
            variables: {
              id,
            },
            manual: true,
            result({ data }) {
              this.loading = false;
              this.userData.id = data.user.id;
              this.userData.email = data.user.email;
              this.userData.firstName = data.user.firstname;
              this.userData.lastName = data.user.lastname;
              this.userData.stores = data.user.stores.collection;

              if (data.user.roles.includes('ROLE_SUPERADMIN')) {
                this.userData.superAdminRole = true;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>

<template>
  <div>
    <table-page
      ref="childData"
      title="Users"
      :get-data="getUsers"
      :loading="loading">
      <el-table
        stripe
        style="width: 100%;"
        :data="users">
        <el-table-column
          width="100"
          prop="_id"
          label="ID">
          <template #default="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="Email">
          <template #default="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column
          prop="firstName"
          label="Firstname">
          <template #default="scope">
            {{ scope.row.firstname }}
          </template>
        </el-table-column>
        <el-table-column
          prop="lastName"
          label="Lastname">
          <template #default="scope">
            {{ scope.row.lastname }}
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          fixed="right"
          header-align="right"
          label="Actions">
          <div
            slot-scope="props"
            class="text-right table-actions">
            <el-tooltip
              content="Settings"
              :open-delay="300"
              placement="top">
              <n-button
                type="warning"
                size="sm"
                icon
                @click.native="handleEdit(props.$index, props.row)">
                <i class="now-ui-icons ui-1_settings-gear-63"></i>
              </n-button>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              :open-delay="300"
              placement="top">
              <n-button
                type="danger"
                size="sm"
                icon
                @click.native="deleteUser(props.$index, props.row)">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </n-button>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </table-page>
  </div>
</template>

<script>
  import { Table, TableColumn } from 'element-ui';
  import TablePage from '@/components/TablePage.vue';
  import Swal from 'sweetalert2';
  import UsersQuery from '@/graphql/users/queries/Users.gql';
  import DeleteUserMutation from '@/graphql/users/mutations/DeleteUserMutation.gql';

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TablePage,
    },
    data() {
      return {
        loading: true,
        users: [],
      };
    },
    mounted() {
      this.getUsers();
    },
    methods: {
      async getUsers(search) {
        try {
          const variables = {
            page: this.$refs.childData.pagination.page,
            itemsPerPage: this.$refs.childData.pagination.perPage,
          };

          if (search) variables.search = search;

          await this.$apollo.addSmartQuery('getUsers', {
            query: UsersQuery,
            variables,
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.users = data.users.collection;
                this.$refs.childData.pagination.total = data.users.paginationInfo.totalCount;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      async handleEdit(event, row) {
        // eslint-disable-next-line no-underscore-dangle
        await this.$router.push({ name: 'users.edit', params: { userId: row._id } });
      },
      async deleteUser(event, row) {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false,
        });

        if (result.value) {
          try {
            await this.$apollo.mutate({
              mutation: DeleteUserMutation,
              variables: {
                id: row.id,
              },
            });

            await this.getUsers();

            this.$root.notifyVue('danger', 'User has been deleted!');
          } catch (error) {
            console.log(error);
          }
        }
      },
    },
  };
</script>

<template>
  <div class="row">
    <label class="col-sm-2 col-form-label">
      {{ title }}
    </label>
    <div class="col-sm-10">
      <fg-input
        :value="modelElement"
        :type="type"
        @input="event => $emit('update:modelElement', event)" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      modelElement: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'text',
      },
    },
  };
</script>

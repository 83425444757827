<template>
  <card>
    <h5
      slot="header"
      class="title">
      Create User
    </h5>
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid, handleSubmit }">
      <form
        method="post"
        class="form-horizontal"
        @submit.prevent="handleSubmit(createUser)">
        <div class="row">
          <label class="col-sm-2 col-form-label">
            Email
          </label>
          <div class="col-sm-10">
            <ValidationProvider
              v-slot="{ passed, failed }"
              name="email"
              rules="required|email">
              <fg-input
                v-model="user.email"
                type="email"
                :error="failed ? 'The Email field is required' : null"
                :has-success="passed"
                name="email" />
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">
            Firstname
          </label>
          <div class="col-sm-10">
            <fg-input
              v-model="user.firstName"
              type="text" />
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">
            Lastname
          </label>
          <div class="col-sm-10">
            <fg-input
              v-model="user.lastName"
              type="text" />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">
            Password
          </label>
          <div class="col-sm-10">
            <ValidationProvider
              v-slot="{ passed, failed }"
              vid="confirmation"
              rules="required">
              <fg-input
                v-model="user.password"
                type="password"
                :error="failed ? 'The Password field is required' : null"
                :has-success="passed"
                name="password" />
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">
            Password Confirmation
          </label>
          <div class="col-sm-10">
            <ValidationProvider
              v-slot="{ passed, failed }"
              name="password"
              rules="required|confirmed:confirmation">
              <fg-input
                v-model="user.confirmation"
                type="password"
                :error="failed ? 'The Password does not match' : null"
                :has-success="passed"
                name="confirmation" />
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">Stores</label>
          <div class="col-sm-10">
            <auto-complete
              ref="autoComplete"
              :selected-items="selectedStores"
              :set-item="setStore"
              :gql-query="storeQuery"
              :fetch-more="fetchMore"
              result="name" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 col-form-label"></div>
          <div class="col-sm-10">
            <el-tag
              v-for="(tag, index) in selectedStores"
              :key="tag.id"
              type="info"
              size="small"
              :closable="true"
              @close="handleClose(index)">
              {{ tag.name }}
            </el-tag>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10 checkbox-radios">
            <checkbox v-model="user.superAdminRole">
              Is Super Admin
            </checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              type="submit"
              class="btn btn-success">
              Create
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
  import { Checkbox } from 'src/components/index';
  import { Tag } from 'element-ui';
  import AutoComplete from '@/components/AutoComplete.vue';
  import { extend } from 'vee-validate';
  import { required, email, confirmed } from 'vee-validate/dist/rules';
  import GetAllStores from '@/graphql/stores/queries/GetAllStores.gql';
  import GetStoreById from '@/graphql/users/queries/GetStoreById.gql';
  import CreateUserMutation from '@/graphql/users/mutations/CreateUserMutation.gql';

  extend('email', email);
  extend('required', required);
  extend('confirmed', confirmed);

  function initialState() {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmation: '',
        superAdminRole: false,
      },

      selectedStores: [],
    };
  }

  export default {
    components: {
      [Tag.name]: Tag,
      Checkbox,
      AutoComplete,
    },
    data() {
      return initialState();
    },
    methods: {
      async storeQuery(variables) {
        await this.$apollo.addSmartQuery('setAllStores', {
          query: GetAllStores,
          variables,
          manual: true,
          result({ data, loading }) {
            if (!loading) {
              this.$refs.autoComplete.items = data.stores.collection;
              this.$refs.autoComplete.lastPage = data.stores.paginationInfo.lastPage;

              if (!data.stores.collection.length) {
                this.$refs.autoComplete.noResults = true;
              }
            }
          },
        });
      },
      async setStore(store) {
        try {
          await this.$apollo.addSmartQuery('getStoreById', {
            query: GetStoreById,
            fetchPolicy: 'no-cache',
            variables: {
              id: store.id,
            },
            manual: true,
            result({ data }) {
              const duplicate = this.selectedStores.some(element => element.id === data.store.id);
              if (!duplicate) {
                this.selectedStores.push({ id: data.store.id, name: data.store.name });
              }
            },
          });
        } catch (error) {
          console.log(error);
        }

        this.$refs.autoComplete.modal = false;
      },
      async fetchMore(variables, oldData) {
        await this.$apollo.queries.setAllStores.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.$refs.autoComplete.items = [...oldData, ...fetchMoreResult.stores.collection];

            if (this.$refs.autoComplete.page === this.$refs.autoComplete.lastPage) {
              this.$refs.autoComplete.showLoadMoreButton = false;
            }
          },
        });
      },
      handleClose(id) {
        this.selectedStores.splice(id, 1);
      },
      async createUser() {
        try {
          const stores = [];
          const roles = ['ROLE_USER'];

          if (this.selectedStores) {
            for (const value of Object.values(this.selectedStores)) {
              stores.push(value.id);
            }
          }

          if (this.user.superAdminRole) {
            roles.push('ROLE_SUPERADMIN');
          }

          // TODO: Unique EMAIL error handling

          await this.$apollo.mutate({
            mutation: CreateUserMutation,
            variables: {
              email: this.user.email,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              password: this.user.password,
              roles,
              stores,
            },
          });

          Object.assign(this.$data, initialState());
          this.$refs.observer.reset();

          this.$root.notifyVue('success', 'User has been created!');
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>

<style>
.el-tag:first-child {
  margin-left: 0!important;
}
</style>

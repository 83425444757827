<template>
  <div class="row">
    <div class="col">
      <edit-profile-form v-if="user" />
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn } from 'element-ui';
  import { mapGetters } from 'vuex';
  import EditProfileForm from './UserProfile/EditProfileForm.vue';

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      EditProfileForm,
    },
    computed: {
      ...mapGetters(['user']),
    },
  };
</script>
<style></style>

import UpdateUserMutation from '@/graphql/users/mutations/UpdateUserMutation.gql';

function initialState() {
  return {
    loading: true,
    userData: {
      email: '',
      firstName: '',
      lastName: '',
      stores: [],
      superAdminRole: false,
    },
  };
}

const updateUser = async _self => {
  let userId;
  const stores = [];
  const userRoles = ['ROLE_USER'];

  if (_self.$route.params.userId) {
    userId = `api/users/${_self.$route.params.userId}`;
  } else {
    userId = _self.userData.id;
  }

  if (_self.userData.superAdminRole) {
    userRoles.push('ROLE_SUPERADMIN');
  }

  if (_self.$refs.childData.selectedStores) {
    for (const value of Object.values(_self.$refs.childData.selectedStores)) {
      stores.push(value.id);
    }
  }

  try {
    await _self.$apollo.mutate({
      mutation: UpdateUserMutation,
      variables: {
        id: userId,
        email: _self.userData.email,
        firstName: _self.userData.firstName,
        lastName: _self.userData.lastName,
        roles: userRoles,
        stores,
      },
    });

    await _self.$refs.childData.getStores();
    _self.$root.notifyVue('warning', 'User has been updated!');
  } catch (error) {
    console.log(error);
  }
};

export { initialState, updateUser };

<template>
  <div>
    <card>
      <h5
        slot="header"
        class="title">
        Update User
      </h5>
      <form
        method="post"
        class="form-horizontal"
        @submit.prevent="updateForm">
        <input-styled
          title="Email"
          :model-element.sync="user.email" />

        <input-styled
          title="Firstname"
          :model-element.sync="user.firstName" />

        <input-styled
          title="Lastname"
          :model-element.sync="user.lastName" />

        <div class="row">
          <label class="col-sm-2 col-form-label">Stores</label>
          <div class="col-sm-10">
            <auto-complete
              ref="autoComplete"
              :selected-items="selectedStores"
              :set-item="setStore"
              :gql-query="storeQuery"
              :fetch-more="fetchMore"
              result="name" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 col-form-label"></div>
          <div class="col-sm-10">
            <el-tag
              v-for="(tag, index) in selectedStores"
              :key="tag.id"
              type="info"
              size="small"
              :closable="true"
              @close="handleClose(index)">
              {{ tag.name }}
            </el-tag>
          </div>
        </div>

        <div
          v-if="$store.getters.roles.includes('ROLE_SUPERADMIN')"
          class="row">
          <label class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10 checkbox-radios">
            <checkbox v-model="user.superAdminRole">
              Is Super Admin
            </checkbox>
          </div>
        </div>

        <!--  TODO: WACHTWOORD TOEVOEGEN    -->

        <div class="row">
          <div class="col">
            <button
              type="submit"
              class="btn btn-warning">
              Edit
            </button>
            <router-link :to="{ name: 'users' }">
              <button class="btn btn-grey ml-3">
                Cancel
              </button>
            </router-link>
          </div>
        </div>
      </form>
    </card>

    <table-page
      ref="childData"
      title="Stores"
      :loading="loading"
      :get-data="getStores">
      <el-table
        stripe
        style="width: 100%"
        :data="stores">
        <el-table-column
          prop="ID"
          label="ID">
          <template #default="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Name"
          label="Name">
          <template #default="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Domain"
          label="Domain">
          <template #default="scope">
            {{ scope.row.domain }}
          </template>
        </el-table-column>
      </el-table>
    </table-page>
  </div>
</template>
<script>
  import { Checkbox } from 'src/components/index';
  import { Table, TableColumn, Tag } from 'element-ui';
  import TablePage from '@/components/TablePage.vue';
  import GetStoreById from '@/graphql/users/queries/GetStoreById.gql';
  import GetSelectedUserStores from '@/graphql/users/queries/GetSelectedUserStores.gql';
  import AutoComplete from '@/components/AutoComplete.vue';
  import GetAllStores from '@/graphql/stores/queries/GetAllStores.gql';
  import inputStyled from '@/components/InputStyled.vue';

  export default {
    components: {
      [Tag.name]: Tag,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Checkbox,
      AutoComplete,
      TablePage,
      inputStyled,
    },
    props: {
      updateForm: {
        type: Function,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        selectedStores: [],
        stores: [],
      };
    },
    mounted() {
      this.getStores();

      this.selectedStores = this.user.stores;
    },
    methods: {
      async storeQuery(variables) {
        await this.$apollo.addSmartQuery('setAllStores', {
          query: GetAllStores,
          variables,
          manual: true,
          result({ data, loading }) {
            if (!loading) {
              this.$refs.autoComplete.items = data.stores.collection;
              this.$refs.autoComplete.lastPage = data.stores.paginationInfo.lastPage;

              if (!data.stores.collection.length) {
                this.$refs.autoComplete.noResults = true;
              }
            }
          },
        });
      },
      async getStores(search) {
        try {
          const variables = {
            id: this.user.id,
            page: this.$refs.childData.pagination.page,
            itemsPerPage: this.$refs.childData.pagination.perPage,
          };

          if (search) variables.search = search;

          await this.$apollo.addSmartQuery('GetSelectedUserStores', {
            query: GetSelectedUserStores,
            variables,
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.stores = data.user.stores.collection;
                this.$refs.childData.pagination.total = data.user.stores.paginationInfo.totalCount;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      async setStore(store) {
        try {
          await this.$apollo.addSmartQuery('getStoreById', {
            query: GetStoreById,
            fetchPolicy: 'no-cache',
            variables: {
              id: store.id,
            },
            manual: true,
            result({ data }) {
              const duplicate = this.selectedStores.some(element => element.id === data.store.id);
              if (!duplicate) {
                this.selectedStores.push({ id: data.store.id, name: data.store.name });
              }
            },
          });
        } catch (error) {
          console.log(error);
        }

        this.$refs.autoComplete.modal = false;
      },
      async fetchMore(variables, oldData) {
        await this.$apollo.queries.setAllStores.fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.$refs.autoComplete.items = [...oldData, ...fetchMoreResult.stores.collection];

            if (this.$refs.autoComplete.page === this.$refs.autoComplete.lastPage) {
              this.$refs.autoComplete.showLoadMoreButton = false;
            }
          },
        });
      },
      handleClose(id) {
        this.selectedStores.splice(id, 1);
      },
    },
  };
</script>

<template>
  <div class="row">
    <div class="col-12">
      <card
        card-body-classes="table-full-width"
        no-footer-line>
        <h4
          slot="header"
          class="card-title">
          {{ title }}
        </h4>

        <loading-panel v-if="loading" />

        <div v-else>
          <div class="col-12 d-flex align-items-center justify-content-sm-between flex-wrap">
            <el-select
              v-model="pagination.perPage"
              class="select-primary mb-3"
              style="width: 200px"
              placeholder="Per page">
              <el-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                class="select-default"
                :label="item"
                :value="item" />
            </el-select>

            <fg-input
              v-model="search"
              type="search"
              class="mb-3"
              clearable
              addon-left-icon="el-icon-search"
              suffix-icon="el-icon-close"
              style="width: 200px"
              placeholder="Search records"
              aria-controls="datatables" />
          </div>

          <slot></slot>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center
            justify-content-sm-between align-items-center
            flex-wrap mt-2">
            <div>
              <p class="card-category mb-0">
                {{ $t('tablePage.totalInfo',
                      {
                        from: from + 1,
                        to: to,
                        total: pagination.total
                      })
                }}
              </p>
            </div>
            <n-pagination
              v-model="pagination.page"
              class="pagination-no-border mb-0"
              :per-page="pagination.perPage"
              :total="pagination.total" />
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
  import { Option } from 'element-ui';
  import { Pagination as NPagination } from 'src/components';
  import LoadingPanel from '@/components/LoadingPanel.vue';

  export default {
    components: {
      [Option.name]: Option,
      LoadingPanel,
      NPagination,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      getData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        search: '',
        awaitingSearch: false,
        pagination: {
          page: 1,
          perPage: 10,
          perPageOptions: [5, 10, 25, 50],
          total: null,
        },
      };
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.pagination.total < highBound) {
          highBound = this.pagination.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.page - 1);
      },
    },
    watch: {
      // TODO: Watchers are double called, FIX THIS
      pagination: {
        handler() {
          if (this.search) {
            this.getData(this.search);
          } else {
            this.getData();
          }
        },
        deep: true,
      },
      search: {
        handler() {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getData(this.search);
              this.awaitingSearch = false;
            }, 300); // 300 milisec delay so you don't catch every input
          }
          this.awaitingSearch = true;
        },
      },
    },
  };
</script>

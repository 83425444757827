var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Create User ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUser)}}},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Email ")]),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required' : null,"has-success":passed,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Firstname ")]),_c('div',{staticClass:"col-sm-10"},[_c('fg-input',{attrs:{"type":"text"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Lastname ")]),_c('div',{staticClass:"col-sm-10"},[_c('fg-input',{attrs:{"type":"text"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Password ")]),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required' : null,"has-success":passed,"name":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Password Confirmation ")]),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password does not match' : null,"has-success":passed,"name":"confirmation"},model:{value:(_vm.user.confirmation),callback:function ($$v) {_vm.$set(_vm.user, "confirmation", $$v)},expression:"user.confirmation"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Stores")]),_c('div',{staticClass:"col-sm-10"},[_c('auto-complete',{ref:"autoComplete",attrs:{"selected-items":_vm.selectedStores,"set-item":_vm.setStore,"gql-query":_vm.storeQuery,"fetch-more":_vm.fetchMore,"result":"name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2 col-form-label"}),_c('div',{staticClass:"col-sm-10"},_vm._l((_vm.selectedStores),function(tag,index){return _c('el-tag',{key:tag.id,attrs:{"type":"info","size":"small","closable":true},on:{"close":function($event){return _vm.handleClose(index)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"}),_c('div',{staticClass:"col-sm-10 checkbox-radios"},[_c('checkbox',{model:{value:(_vm.user.superAdminRole),callback:function ($$v) {_vm.$set(_vm.user, "superAdminRole", $$v)},expression:"user.superAdminRole"}},[_vm._v(" Is Super Admin ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v(" Create ")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }